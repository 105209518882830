const person = {
    name: 'Josh Jackson',
    role: 'CEO & Founder',
    imageUrl: 'https://res.cloudinary.com/dnhbo3j62/image/upload/c_crop,ar_3:4/v1730424421/IMG_1303_2_o1jjmr.png',
    bio: 'Your bio goes here. Add a longer description about your background, mission, and vision for the company. Feel free to elaborate on your journey, goals, and what you aim to achieve.',
  }
  
  export default function About() {
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-4xl px-6 lg:px-8 text-center">
          <h2 className="text-5xl font-semibold tracking-tight text-gray-900">
            Nice to meet you
          </h2>
          <p className="mt-6 text-lg text-gray-600">
            .
          </p>
        </div>
        <div className="mx-auto mt-20 max-w-6xl px-6 lg:px-8 flex flex-col sm:flex-row items-center sm:items-start">
          <img
            alt="Josh Jackson"
            src={person.imageUrl}
            className="w-1/3 rounded-2xl object-cover object-center mr-8"
          />
          <div className="text-left">
            <h3 className="text-4xl font-semibold tracking-tight text-gray-900">{person.name}</h3>
            <p className="text-2xl text-gray-600">{person.role}</p>
            <p className="mt-6 text-lg text-gray-600">{person.bio}</p>
          </div>
        </div>
      </div>
    )
  }