import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header.js';
import HeroSection from './components/HeroSection.js';
import ReleaseNotes from './components/ReleaseNotes.jsx';
import Testing from './components/Testing.jsx';
import Pricing from './components/pricing/Pricing.jsx';
import About from './components/about/About.jsx'; // Importing About component

function App() {
  return (
    <Router>
      <div className="bg-white">
        <Header />
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/release-notes" element={<ReleaseNotes />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<About />} /> {/* New About route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;